import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './BookAnAppointment.scss';
import emailjs from 'emailjs-com';
import { Helmet } from "react-helmet";
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import Google from '../../Images/Google.png'
import Trust from '../../Images/Trust.png'
import Porch from '../../Images/Porch.png'
const BookAnAppointment = ({ setBookAnAppointment }) => {
    const [Page1, setPage1] = useState(true);
    const [Page2, setPage2] = useState(false);
    const [Page3, setPage3] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [FormSubmited, setFormSubmited] = useState(false);
    const [CustInfoUpdate, setCustInfoUpdate] = useState(false);

    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Chimney Inspection Lvl 1',
        Date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: 'CA',
        Zipcode: '',
        Note: ''
    });

    const FinalMessage = `
      ROTO  
      # Ventura, CA Chimney Sweep
      Name: ${CustInfo.FirstName + ' ' + CustInfo.LastName}
      Phone: ${CustInfo.PhoneNumber}
      Address: ${CustInfo.Address1 + ', ' + CustInfo.City + ', ' + CustInfo.State + ', ' + CustInfo.Zipcode + ' ' + CustInfo.Address2}
      Type: ${CustInfo.Service}
      Time: ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time}
      Note: ${CustInfo.Note}`

    const location = useLocation().pathname
    const navigate = useNavigate()

    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_n0o8yjw', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setPage1(false)
                setPage2(false)
                setPage3(true)
                setFormSubmiting(false)
                navigate('/thanks')
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className="BookAnAppointmentMainComponent" style={location == '/' || location == '/sitemap' ? { "top": "60px" } : { "top": "100px" }}>
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Schedule Chimney Service Online | Ventura Chimney Sweep | 805-954-9097</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Schedule Chimney Service Online | Ventura Chimney Sweep | 805-954-9097" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Schedule Chimney Service Online | Ventura Chimney Sweep | 805-954-9097" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />


                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.chimneysweepventura.org/book" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Easily schedule your professional chimney service online with Ventura Chimney Sweep. Book inspections, cleanings, and repairs at your convenience to keep your chimney safe and efficient." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Easily schedule your professional chimney service online with Ventura Chimney Sweep. Book inspections, cleanings, and repairs at your convenience to keep your chimney safe and efficient." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Schedule Chimney Service Online | Ventura Chimney Sweep | 805-954-9097" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Faq Page" data-react-helmet="true" />
            </Helmet>
            <div className="BookAnAppointmentMainComponentTop">
                <h1>SCHEDULE SERVICE ONLINE</h1>
                <h2>No extra charge for appointments on nights, weekends or holidays</h2>
            </div>
            <div>
                <div className="BookAnAppointmentMainComponentMaps">
                    <h4 style={Page1 || Page2 || Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>1. Information </h4>
                    <h4 style={Page2 || Page2 || Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>2. Address </h4>
                    <h4 style={Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>3. Note </h4>
                </div>
                {
                    Page1 ?
                        <div className="BookAnAppointmentMainComponentPage1">
                            <div className="BookAnAppointmentMainRightFullName">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>First Name <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.FirstName}
                                        className='BookAnAppointmentMainRightBoxFirstName'
                                        onChange={(e) => {
                                            CustInfo.FirstName = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Last Name <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.LastName}
                                        className='BookAnAppointmentMainRightBoxLastName'
                                        onChange={(e) => {
                                            CustInfo.LastName = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightPhoneAndMaill">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Phone Number <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.PhoneNumber}
                                        className='BookAnAppointmentMainRightBoxPhone'
                                        onChange={(e) => {
                                            CustInfo.PhoneNumber = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="tel" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Email Address (Optional)</h4>
                                    <input
                                        defaultValue={CustInfo.Email}
                                        onChange={(e) => {
                                            CustInfo.Email = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightServices">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Service Needed <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.Service}
                                        onChange={(e) => {
                                            CustInfo.Service = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} name="" id="">
                                        <option value="Chimney Inspection Lvl 1">Chimney Inspection Lvl 1</option>
                                        <option value="Chimney Inspection Lvl 2">Chimney Inspection Lvl 2</option>
                                        <option value="Chimney Inspection Lvl 1 & Cleaning">Chimney Inspection Lvl 1 & Cleaning</option>
                                        <option value="Chimney Inspection Lvl 2 & Cleaning">Chimney Inspection Lvl 2 & Cleaning</option>
                                    </select>
                                    <h3>Technician will provide estimate for free on-site. Payment will be required only if/when service is done.</h3>

                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightDateAndTime">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Appointment Date <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.Date}
                                        className='BookAnAppointmentMainRightBoxDate'
                                        onChange={(e) => {
                                            CustInfo.Date = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="date" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Appointment Time <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.Time}
                                        className='BookAnAppointmentMainRightBoxTime'
                                        onChange={(e) => {
                                            CustInfo.Time = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} >
                                        <option value="8:00AM-11:00AM">8:00AM-11:00AM</option>
                                        <option value="11:00AM-01:00PM">11:00AM-01:00PM</option>
                                        <option value="01:00PM-03:00PM">01:00PM-04:00PM</option>
                                        <option value="04:00PM-07:00PM">04:00PM-07:00PM</option>
                                        <option value="Ask for a Representative">Ask for a Representative</option>
                                    </select>
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {

                                    if (!CustInfo.FirstName) { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.LastName) { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.PhoneNumber) { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.Date) { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.Time) { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "1px solid #6f6f6f61"; }

                                    if (
                                        CustInfo.FirstName &&
                                        CustInfo.LastName &&
                                        CustInfo.PhoneNumber &&
                                        CustInfo.Service &&
                                        CustInfo.Date &&
                                        CustInfo.Time
                                    ) {
                                        setPage1(false)
                                        setPage2(true)
                                    } else {
                                        console.log('no')
                                    }
                                }}>NEXT</button>
                            </div>
                        </div>
                        : null
                }
                {
                    Page2 ?
                        <div className="BookAnAppointmentMainComponentPage2">
                            <div className="BookAnAppointmentMainRightAddress1">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Address Line 1 <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxAddress1'
                                        defaultValue={CustInfo.Address1}
                                        onChange={(e) => {
                                            CustInfo.Address1 = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightAddress2">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Address Line 2 (Optional)</h4>
                                    <input
                                        defaultValue={CustInfo.Address2}
                                        onChange={(e) => {
                                            CustInfo.Address2 = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightAddress3">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>City <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxCity'
                                        defaultValue={CustInfo.City}
                                        onChange={(e) => {
                                            CustInfo.City = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>State <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.State}
                                        onChange={(e) => {
                                            CustInfo.State = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }}>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Zipcode <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxZipcode'
                                        defaultValue={CustInfo.Zipcode}
                                        onChange={(e) => {
                                            CustInfo.Zipcode = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="tel" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {

                                    setPage1(true)
                                    setPage2(false)
                                    setPage3(false)
                                }}>PREVIOUS</button>
                                <button onClick={() => {
                                    console.log(CustInfo)
                                    if (!CustInfo.Address1) { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.City) { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "1px solid #6f6f6f61"; }
                                    if (!CustInfo.Zipcode) { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "1px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "1px solid #6f6f6f61"; }

                                    if (
                                        CustInfo.Address1 &&
                                        CustInfo.City &&
                                        CustInfo.Zipcode
                                    ) {
                                        setPage1(false)
                                        setPage2(false)
                                        setPage3(true)
                                    } else {
                                        console.log('no')
                                    }

                                }}>NEXT</button>
                            </div>
                        </div>
                        : null
                }
                {
                    Page3 ?
                        <div className="BookAnAppointmentMainComponentPage3">
                            <div className="BookAnAppointmentMainRightNote">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Note</h4>
                                    <textarea
                                        defaultValue={CustInfo.Note}
                                        onChange={(e) => {
                                            CustInfo.Note = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            console.log(CustInfo)
                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {
                                    setPage1(false)
                                    setPage2(true)
                                    setPage3(false)
                                }}>PREVIOUS</button>

                                {
                                    !FormSubmiting ?
                                        <form className="contact-form" onSubmit={sendEmail}>
                                            <input type="hidden" name="from_name" defaultValue={FinalMessage} />
                                            {/* Cust Info */}
                                            <input type="hidden" name="Date" defaultValue={CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time} />
                                            <input type="hidden" name="Service_Type" defaultValue={CustInfo.Service} />
                                            <input type="hidden" name="Send_To" defaultValue={CustInfo.Email} />
                                            <input type="hidden" name="Cust_Name" defaultValue={CustInfo.FirstName} />
                                            <input type="hidden" name="Company_From" defaultValue={`Ventura, CA Chimney Sweep | ROTO`} />
                                            <input type="hidden" name="Company" defaultValue={`Ventura, CA Chimney Sweep | ROTO | ${CustInfo.City + ', ' + CustInfo.State}`} />
                                            <input type="submit" value="SEND" />
                                        </form>
                                        :
                                        null
                                }

                                {
                                    FormSubmiting ?
                                        <button class="buttonload">
                                            <i class="fa fa-refresh fa-spin"></i>Loading
                                        </button>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        : null
                }
                <div className="BookAnAppointmentMainComponentEmergery">
                    <div className="BookAnAppointmentMainComponentRates">
                        <div className="BookAnAppointmentMainComponentRatesImages">
                        <img src={YELP} alt="Ventura Air Duct Cleaning Yelp Review" />
                        <img src={Angie} alt="Ventura Air Duct Cleaning Angie Review" />
                        <img src={HomeAdvisor} alt="Ventura Air Duct Cleaning HomeAdvisor Review" />
                        <img src={BBB} alt="Ventura Air Duct Cleaning BBB Review" />
                        <img src={Google} alt="Ventura Air Duct Cleaning Google Review" />
                        <img src={Porch} alt="Ventura Air Duct Cleaning Porch Review" />
                        <img src={Trust} alt="Ventura Air Duct Cleaning Trust Review" />
                        </div>
                        <h1>Customer Testimonials</h1>
                        <h2>⭐⭐⭐⭐⭐</h2>
                        <h3>I’ve used Ventura Chimney Sweep for both cleaning and repairs, and I’m always impressed with their level of service. They use top-quality materials and are very detail-oriented. My chimney has never been in better shape, and I appreciate their commitment to safety and customer satisfaction.</h3>
                    </div>
                    <div className="BookAnAppointmentMainComponentEmergeryBox">
                        <a href="tel:805-954-9097">For emergency service call: 805-954-9097</a>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default BookAnAppointment;
